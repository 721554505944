<template>
    <v-container fluid>
    <v-row>
    <v-col cols=12 md=12 lg=5 >
    <page-card icon="mdi-printer" title="Print Packets" >
            <template v-slot:headerRight>
                <v-btn
                    color="success"
                    fab
                    small
                    :loading="addLoading"
                    @click="addPacket()"
                    >
              <v-icon >mdi-plus</v-icon>
            </v-btn>
            </template>
            <v-pagination
            v-model="page"
            :length="Math.ceil(packets.foundRows/packets.limit)"
            :total-visible="7"
            ></v-pagination>
            <v-list dense>
                <div v-for="(packet, packetIndex) in packets.data" :key="packetIndex">
                    <v-hover>
                        <template  v-slot:default="{ hover }">
                        <v-list-item 
                            two-line
                            @click="openPacketDialog(packet)" 
                            :class="`elevation-${hover ? 5 : 0}`"
                            >
                            <v-list-item-title><strong>{{packet.lastName}}, {{packet.firstName}}</strong></v-list-item-title>
                            <v-list-item-subtitle > {{formatDate(packet.date)}}  {{packet.executingAgent}}</v-list-item-subtitle>
                        </v-list-item>
                        </template>
                    </v-hover>
                    <v-divider></v-divider>
                </div>
            </v-list>
            <v-dialog v-model="packetDialog" :fullscreen = "isMobile">
                <v-card>
                    <v-toolbar dark color="primary">
                    <v-btn icon dark @click="packetDialog = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Print Packet</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                            color="error"
                            dark
                            v-bind="attrs"
                            v-on="on"
                            >
                            Delete Packet
                            </v-btn>
                        </template>
                        <v-list color="error">
                            <v-list-item dark color="error" @click="deletePacket(packet)">
                            <v-list-item-title >Yes Delete</v-list-item-title>
                            </v-list-item>
                        </v-list>
                        </v-menu>
                    </v-toolbar>
                    <v-card-title> </v-card-title>
                    <v-card-text>
                        <packet v-model="packet"></packet>   
                    </v-card-text>
                </v-card>
            </v-dialog>
    </page-card>
    </v-col>
    </v-row>
    </v-container>
</template>

<script>
    import apiMixin from '../mixins/apiMixin'
    import appSearch from '../mixins/appSearchMixin'
    import overLoading from '../mixins/overLoading'
    import Packet from '../components/Packet'
    import format from '../mixins/formatMixin'

    export default {
        name: "PrintPackets",
        components: {
            Packet
        },
        mixins: [apiMixin, appSearch, overLoading, format],
        data() {
        return {
            addLoading: false, 
            packetDialog: false,
            packet: false,
            page: 1,
            value: null,
            packets: {},
        }
        },
        watch: {
            dialog (val) {
                this.bond = (val) ? this.bond :  null
            },
            page: function() {
                this.getPackets()
            },
            appSearch () {
                this.page = 1
                this.getPackets()
            },
            packet () {
                console.log("the packet has changed")
            },
            packetDialog() {
                console.log("packetDialog changed")
                this.getPackets()
            }
        },
        created() {
            this.getPackets()
            this.appSearch = ""
        },
        methods :{
            deletePacket(packet) {
                let payload = {
                    table: "mpackets",
                    where : {
                        id: packet.id
                    }
                }
                this.apiDelete(payload).then(()=>{
                    this.getPackets()
                    this.packet = null
                    this.packetDialog = false
                })
            },
            addPacket() {
                this.addLoading=true
                var today = new Date();
                var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();

                this.overLoading = true
                var payload =  {
                    table: "mpackets",
                    values: {
                        date: date
                    }
                }
                
                console.log("add Packet", payload)
            // this.data = await this.apiRead(payload)
                this.apiCreate(payload).then((response) => {
                    this.addLoading=false
                    let that = this
                    this.getPackets().then(() => {
                        that.openPacketDialog(response)
                        // console.log("that.packet.data", that.packets)
                        // that.packet.dialog = true
                    })
                })
            },
            openPacketDialog(packet) {
                this.packet = packet
                this.packetDialog= true
            },
            async getPackets () {
                this.overLoading = true
                console.log("getting bonds")
                const rowCount = 20
                const offset = (this.page - 1) * rowCount
                var payload =  {
                    offset: offset,
                    rowCount: rowCount,
                    table: "mpackets",
                    search: {
                        expression :"CONCAT( IFNULL(lastName,''),', ',IFNULL(firstName,''), ' ',IFNULL(date,''),IFNULL(execAgent,''))",
                        pattern:  "%" + this.appSearch + "%"
                    },
                    orderBy: " date DESC"
                }
                
                // this.data = await this.apiRead(payload)
                this.apiRead(payload).then((response) => {
                    //make the index the same as the ID 
                    // this.packets = []
                    // for (const packet of response.data) {
                    //     this.packets[packet.id] = packet
                    // }
                    this.packets = response
                    this.overLoading = false
                })
            },
        }

    }
</script>