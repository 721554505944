<template>
    <div >
    <v-progress-linear
      indeterminate
      color="primary"
      v-if="loading"
    ></v-progress-linear>
        <v-card elevation="0" >
            <v-container ma-0 pa-0>
                <v-row v-for="(row, rowIndex) in rows" :key="rowIndex" dense>
                    <v-col v-for="col in row" :key="col.key" v-bind="col" dense >
                        <v-text-field v-model="packet[col.key]" dense v-bind="col" outlined @blur="updatePacket({[col.key]: packet[col.key]})"></v-text-field>
                    </v-col>
                </v-row>
            </v-container>
            <v-dialog v-model="cPickDialog" max-width="500" :fullscreen = "isMobile">
                <m-customer-picker v-on:customerSelect="addCollateral" :loading="collateralLoading"></m-customer-picker>
            </v-dialog>
            <v-container class="ml-0 pl-0 pt-0" fluid>
                <v-row dense>
                    <v-col :class="{'d-inline-flex':!isMobile}">
                        <v-card class="overflow-x-auto" elevation="0"> 
                            <v-card-title class="pb-0 pt-0">Indemnitors and Collateral</v-card-title>
                            <m-table
                                :headers="collHeaders"
                                :data="collateral"
                                :crud="true"
                                v-on:deleteRow="deleteCollRow"
                                v-on:edit="onEditColl"
                                v-on:addRow="cPickDialog=true"
                                class="pb-1"
                            ></m-table>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row  dense>
                    <v-col :class="{'d-inline-flex':!isMobile}">
                        <v-card class="overflow-x-auto" elevation="0">
                            <v-card-title class="pb-0 pt-0">Bonds</v-card-title>
                            <m-table 
                                :headers="bondHeaders" 
                                :data="bonds" 
                                v-on:edit="onEdit" 
                                v-on:deleteRow="deleteRow"
                                v-on:addRow="addBond"
                                :crud="true"  
                                :loading="bondsLoading"
                                class="pb-1"></m-table>      
                        
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions>
                <v-btn
                    color="primary"
                    @click="printBondForms()"
                    rounded>
                    Print Bond Forms
                </v-btn>
                <v-btn
                    color="primary"
                    @click="printPowers()"
                    rounded>
                    Print Powers
                </v-btn>
            </v-card-actions>
            <fill-pdf v-model="print.visible" :data="print.data" ></fill-pdf>
        </v-card>
    </div>
</template>


<script>
    import apiMixin from '../mixins/apiMixin'
    import formatMixin from '../mixins/formatMixin'
    import appSearch from '../mixins/appSearchMixin'
    import overLoading from '../mixins/overLoading'
      
    export default {
        name: "Packet",
        props: ['value'],
        components: {
        },
        mixins: [apiMixin, appSearch, overLoading, formatMixin],
        data() {
        return {
            packet: {},
            cPickDialog: false,
            print: {
                visible:false,
                data: []
            },
            dialog: false, 
            page: 1,
            loading: false,
            rows: [ 
                    [
                        {label: "Packet Date",          key:"date",             lg:2, sm: 6, cols:12,     type:"date"},
                    ],
                    [
                        {label: "Last Name",            key:"lastName",     lg:6, sm: 6, cols:12},
                        {label: "First Name",           key:"firstName",    lg:6, sm: 6, cols:12},
                        
                        // {label: "Agent",                key:"agent",        md:4, sm: 6, cols:12},
                    ],
                    [
                        {label: "DOB",                  key:"DOB",          lg:2, sm: 6, cols:12,         type:"date"},
                        {label: "sex",                  key:"sex",              lg:1, sm: 6, cols:12},
                        {label: "race",                 key:"race",             lg:1, sm: 6, cols:12},
                        {label: "DOA",                  key:"DOA",              lg:2, sm: 6, cols:12,     type:"date"},
                        {label: "Booking Number",       key:"inmateBookingNum", lg:2, sm: 6, cols:12},
                    ],
                    [
                        {label: "Executing Agent",      key:"execAgent",        cols:12, sm:6}
                    ] 
                ],
                collHeaders: [
                    {label: "Coll Num",       key: "recptNum",      type: "text",         edit: true},
                    {label: "Vehicle Title",  key: "vehicleTitle",  type: "switch",       edit: true},
                    {label: "Mortgage",       key: "mortgage",      type: "switch",       edit: true},
                    {label: "Cash",           key: "cash",          type: "currency",     edit: true},

                    {label: "Last Name",      key: "lastName",      type: "text"},
                    {label: "First Name",     key: "firstName",     type: "text"},
                    {label: "DOB",            key: "DOB",           type: "date"},

                ],
                bondHeaders: [
                    {label: "Pre",            key: "pre",         type: "select",       edit: true,     options:["U5", "U10", "U25", "U50", "U100", "U150", "U200", "U250"]},
                    {label: "Power Num",      key: "number",      type: "number",       edit: true},
                    {label: "Liability",      key: "liability",   type: "currency",     edit: true},
                    {label: "Exec Date",      key: "execDate",    type: "date",         edit: true},
                    {label: "Charge",         key: "charge",      type: "text",         edit: true},
                    {label: "Case Number",    key: "caseNumber",  type: "text",         edit: true},
                    {label: "County",         key: "county",      type: "text",         edit: true},
                    {label: "Court",          key: "court",       type: "select",       edit: true,     options:["County", "Circuit", "CC1","CC2", "CC3", "CC4", "TC1", "TC2", "TC3", "TC4"]},
                    {label: "Room",           key: "room",        type: "text",         edit: true},
                    {label: "City",           key: "city",        type: "text",         edit: true},
                    {label: "Court Date",     key: "courtDate",   type: "dateTime",     edit: true},
                ],
                data: [],
                bonds: [],
                collateral: [],
                bondsLoading: null,
                collateralLoading: null
            }
        },
        created() { 
            this.getPacket()
            this.getCollateral()
            this.getBonds()
        },
        watch: {
            value: function(val) {
                if (val.id) {
                    this.getBonds()
                    this.getCollateral()
                    this.getPacket()
                } else {
                    this.bonds = []
                    this.collateral = []
                    this.packet = []
                }
                this.$emit('input', val)
            }
        },
        computed: {
            bondFormData: function() {
                const allBonds = this.bonds
                const packet = this.value
                console.log ("bondFormData", allBonds)  
                let formData = []
                let that= this
                const collateral= this.collateral

                let AgencyName = "Moncrief Bail Bonds"
                let AgencyAddress = "3910 S. John Young Parkway"
                let AgencyCityState = "Orlando, FL 32839"
                let AgencyPhoneNumber = "(407) 423-2000"

                // DEFINING COLLATERAL FIELDS
                let collNo = ''
                let indNames = ''
                let coma = ''
                let checks ={
                    PromNote:   true,
                    IndAgg:     true,
                    Mortgage:   false,
                    VehTitle:   false,
                    Cash:       false,
                    CollatNum:  true,
                    Othercollat:false
                }
                for (let i = 0; i < collateral.length; i++) { 
                    collNo = (collateral[i].recptNum) ? collNo + coma + collateral[i].recptNum : collNo
                    indNames = (collateral[i].lastName) ? indNames + coma + collateral[i].firstName + " " + collateral[i].lastName : indNames
                    coma = ', '
                    if (collateral[i].vehicleTitle) {checks.VehTitle = true}
                    if (collateral[i].mortgage)     {checks.Mortgage = true}
                    if (collateral[i].cash>0)       {checks.Cash = true}

                }
                console.log("dob", packet.DOB)
                console.log("dobformat", this.formatDate(packet.DOB))

                allBonds.forEach(function(bonds){
                     formData.push({
                                BondNo:             {type: 'text',      data: bonds.pre + ' ' + bonds.number},
                                CaseNo:             {type: 'text',      data: (bonds.caseNumber) ? bonds.caseNumber : ''},
                                InmateNo:           {type: 'text',      data: (packet.inmateBookingNum) ? packet.inmateBookingNum : ''},
                                race:               {type: 'text',      data: (packet.race) ? packet.race : ''},
                                sex:                {type: 'text',      data: (packet.sex) ? packet.sex: ''},
                                DOB:                {type: 'text',      data: that.formatDate(packet.DOB)},
                                DOA:                {type: 'text',      data: that.formatDate(packet.DOA)},
                                ['Def Name']:       {type: 'text',      data: packet.lastName + ', ' + packet.firstName},
                                Court:              {type: 'text',      data: (bonds.court) ? bonds.court : ''},
                                County:             {type: 'text',      data: (bonds.county) ? bonds.county : ''},
                                CourtCity:          {type: 'text',      data: (bonds.city) ? bonds.city : ''},
                                CourtRoom:          {type: 'text',      data: (bonds.room) ? bonds.room : ''},
                                Liablity:           {type: 'text',      data: (bonds.liability) ? bonds.liability : ''},
                                Premium:            {type: 'text',      data: (bonds.liability) ? that.getPremium(bonds.liability).toString() : ''},
                                Charge:             {type: 'text',      data: (bonds.charge) ? bonds.charge : ''},
                                ExecAgent:          {type: 'text',      data: (packet.execAgent) ? packet.execAgent : ''},
                                CourtMonth:         {type: 'text',      data: (bonds.courtDate) ? that.formatGetDateMonth(that.formatDateTimeZoneOffset(that.formatMysqlDTtoObj(bonds.courtDate))) : ''},
                                CourtYear:          {type: 'text',      data: (bonds.courtDate) ? (that.formatMysqlDTtoObj(bonds.courtDate).getYear() - 100).toString() : ''},                                
                                CourtDay:           {type: 'text',      data: (bonds.courtDate) ? that.formatMysqlDTtoObj(bonds.courtDate).getDate().toString() : ''},
                                CourtTime:          {type: 'text',      data: (bonds.courtDate) ? that.formatTimeFromDT(that.formatMysqlDTtoObj(bonds.courtDate)) : ''},
                                ExecDay:            {type: 'text',      data: (bonds.execDate)  ? that.formatMysqlDtoObj(bonds.execDate).getDate().toString() : ''}, 
                                ExecYear:           {type: 'text',      data: (bonds.execDate)  ? (that.formatMysqlDtoObj(bonds.execDate).getYear() - 100).toString() : ''},                                
                                ExecMonth:          {type: 'text',      data: (bonds.execDate)  ? that.formatGetDateMonth(that.formatMysqlDtoObj(bonds.execDate)) : ''},                                
                                Stamp:              {type: 'text',      data: [ AgencyName,
                                                                            AgencyAddress,
                                                                            AgencyCityState, AgencyPhoneNumber
                                                                            ].join('\n'),},
                                NumberLiability:        {type: 'text',  data: that.getPremium(bonds.liability).toString()},
                                WritenLiability:        {type: 'text',  data: that.formatNumInWords(that.getPremium(bonds.liability))}, 
                                CollNo:                 {type: 'text',  data: collNo},
                                IndemnitorNames:        {type: 'text',  data: indNames},
                                AgencyName:             {type: 'text',  data: AgencyName},                                
                                AgencyAddress:          {type: 'text',  data: AgencyAddress + ' ' + AgencyCityState},
                                State:                  {type: 'text',  data: "FL"},
                                CountyCheck:            {type: 'checkbox', data: (bonds.court == 'County')     ? true : false},
                                CircuitCheck:           {type: 'checkbox', data: (bonds.court == 'Circuit')    ? true : false},
                                PromissoryCheck:        {type: 'checkbox', data: checks.PromNote},
                                IndemnitorCheck:        {type: 'checkbox', data: checks.IndAgg},
                                MortgageCheck:          {type: 'checkbox', data: checks.Mortgage},
                                VehicleCheck:           {type: 'checkbox', data: checks.VehTitle},
                                CashCheck:              {type: 'checkbox', data: checks.Cash},
                                CollRecptCheck:         {type: 'checkbox', data: checks.CollatNum},
                                OtherCollateralCheck:   {type: 'checkbox', data: checks.Othercollat},
        
                    })
                })
                return formData
            }
        },
        methods: {
            addCollateral(customer) {
                this.collateralLoading = true
                let payload = {
                    table: 'mcollateralreceipts',
                    values: {
                        mpacketid: this.value.id,
                        mcustomerid: customer.id
                    }
                }
                this.apiCreate(payload).then(() => {
                    this.getCollateral()
                    this.collateralLoading =  false
                    this.cPickDialog = false
                })
                this.cPickDialog = false
                console.log("addcollateral cpickdialog", this.cPickDialog)
                
            },
            async getCollateral() {
                this.collateralLoading=true
                var payload =  {
                    where: {
                        mpacketid: this.value.id,
                    },
                }
                this.apiReadCollatRecpts(payload).then((response)=>{
                    this.collateral = (response.data) ? response.data : []
                    this.collateralLoading=false
                })
            },
            getPremium(liability) {
                if (liability == 0) {
                    return 0;
                } else if (liability <= 1000) {
                    return 100;
                } else {
                    return liability * .1;
                }
            },
            printBondForms() {
                this.$forceUpdate();
                const bondsdata = this.bondFormData
                let printData = []
                let that = this
                bondsdata.forEach(function(bond){
                     printData.push({
                         form: that.apiRootURL + 'Forms/bondForm.pdf',
                         data: bond
                     })
                })
                this.print.data = printData
                this.print.visible = true
            },
            printPowers() {
                this.$forceUpdate();
                const bondsdata = this.bondFormData
                let printData = []
                let that = this
                bondsdata.forEach(function(bond){
                     printData.push({
                         form: that.apiRootURL + 'Forms/power.pdf',
                         data: bond
                     })
                     printData.push({
                         form: that.apiRootURL + 'Forms/power.pdf',
                         data: bond
                     })
                     printData.push({
                         form: that.apiRootURL + 'Forms/power.pdf',
                         data: bond
                     })
                     printData.push({
                         form: that.apiRootURL + 'Forms/power.pdf',
                         data: bond
                     })
                })
                this.print.data = printData
                this.print.visible = true
            },
            deleteRow(record){
                let payload = {
                    table: "mbonds",
                    where : {
                        id: record.id
                    }
                }

                this.apiDelete(payload).then(()=>{
                    this.getBonds()
                })
            },
            deleteCollRow(record){
                let payload = {
                    table: "mcollateralreceipts",
                    where : {
                        mcrid: record.mcrid
                    }
                }

                this.apiDelete(payload).then(()=>{
                    this.getCollateral()
                })
            },
            onEditColl(record) {
                console.log("onEditColl0", record)
                this.collateralLoading=true
                let payload = {
                    table: "mcollateralreceipts",
                    values: {
                        [record.key]: record.value
                    },
                    where: {
                        mcrid: record.row.mcrid      
                    }
                }
                console.log("onEditColl", payload)
                this.apiUpdate(payload).then(()=>{
                    this.collateralLoading=false
                    this.getCollateral()
                })
            },
            onEdit(record) {
                console.log("onEdit record", record)
                let payload = {
                    table: "mbonds",
                    values: {
                        [record.key]: record.value
                    },
                    where: {
                        id: record.row.id      
                    }
                }
                this.apiUpdate(payload).then(()=>{
                    this.getBonds()
                })
                
            },
            async getPacket () {
                console.log("getting applicattions")
                var payload =  {
                    table: "mpackets",
                    where: {
                        id: this.value.id
                    }
                }
                
                // this.data = await this.apiRead(payload)
                this.apiRead(payload).then((response) => {
                    this.packet =  response.data[0]
                    this.loading = false
                })
            },
            async getBonds() {
                this.bondsLoading = {
                    color: "primary"
                }
                console.log("get bonds")
                let payload = {
                    table: "mbonds",
                    where: {
                        mPacketID: this.value.id
                    }
                }

                this.apiRead(payload).then((response) =>{
                    let data = (response.data) ? response.data : []
                    this.bonds = data
                    this.bondsLoading = null
                })

                console.log("get bonds")
            },
            async addBond() {
                let bonds= this.bonds
                let autoVals = {
                    execDate: this.value.date
                }

                if (bonds.length) {
                    console.log("bonds length", bonds.length)
                    let bond = bonds[bonds.length - 1]
                    console.log("courtDate", bond.courtDate)
                    autoVals = {
                        ...autoVals,
                        pre: bond.pre,
                        number: parseInt(bond.number, 10) + 1,
                        caseNumber: bond.caseNumber,
                        county: bond.county,
                        court: bond.court,
                        city: bond.city,
                        courtDate: bond.courtDate,
                        room: bond.room
                    }    
                }


                let payload = {
                    table: 'mbonds',
                    values: {
                        mPacketID: this.value.id,
                        ...autoVals
                    }
                }
                this.apiCreate(payload).then(() => {
                    this.getBonds()
                })
            },
            async updatePacket(values) {
                this.loading=true
                // let that=this
                let Payload = {
                    table: "mpackets",
                    values: values,
                    where: {
                        id: this.value.id
                    }
                }

                this.apiUpdate(Payload).then((response) => {
                    console.log("update response", response)
                    // that.loading=false
                    this.value = response.confirm
                    this.packet = response.confirm
                    this.getBonds()
                    this.loading=false
                })
            }
        }
    }

</script>